<template>
  <div class="offline-bill-container">
    <!-- 修改账单 -->
    <CSDialog
      :dialogTitle="disposeResTitle"
      dialogConfirmBtnText="确认"
      :dialogVisible="orderContentVisible"
      dialogWidth="1012px"
      @onClose="orderContentVisible = false"
      @onConfirm="reopenWorkOrder"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >支付渠道</label
          >
          <div class="paymentMethod_cs">
            <div
              class="content-checkbox field-item-content"
              @click="selectPayment(index)"
              v-for="(item, index) in selectPaymentList"
              :key="index"
            >
              <img
                :src="editRecharge.target == index ? icon3 : icon5"
                class="imgSele"
              />
              <span
                class="spanSele"
                style="font-size: 24px; vertical-align: middle"
                >{{ item }}</span
              >
            </div>
          </div>
        </div>

        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >缴费金额</label
          >
          <span>{{editRecharge.actualPayable}}元</span>
        </div>

        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >付款凭证</label
          >
          <div style="display: inline-block; vertical-align: top">
            <template v-for="(src, index) in editRecharge.photos">
              <div
                class="commodity-img commodit"
                :key="index"
                style="display: inline-block; position: relative"
              >
                <img
                  :src="src"
                  style="width: 100px; height: 100px; margin-right: 20px"
                />
                <img
                  @click="deleteFacePhoto(index)"
                  src="../assets/icon4.png"
                  style="
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                  "
                />
              </div>
            </template>
            <template v-if="editRecharge.photos.length < 2">
              <div
                class="upload-photo"
                style="width: 100px; height: 100px"
                @click="emitChoosePhoto"
              >
                <div class="text-center">
                  <img
                    src="@/assets/upload.png"
                    alt=""
                    style="margin: 5px auto 5px"
                  />
                  <p style="color: #999">上传照片</p>
                </div>
              </div>
              <input
                type="file"
                class="file"
                accept="images/*"
                id="uploadOwnerPhoto"
                hidden
                @change="showImage($event)"
              />
            </template>
          </div>
        </div>
        <div style="margin-bottom: 23px">
          <label style="width: 200px; margin-right: 50px; text-align: right"
            >备注</label
          >
          <textarea
            class="el-input"
            placeholder="请输入"
            v-model="editRecharge.textarea"
          >
          </textarea>
        </div>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import * as icon5 from "@/assets/iconK.svg";
import * as icon3 from "@/assets/iconS.svg";
import { pcOffLinePropUrl } from "@/requestUrl";
export default {
  data() {
    return {
      icon5,
      icon3,
      editRecharge: {
        target: 0, //支付方式
        textarea: "", //备注
        photos: [], //上传图片数组
        id: "",
        actualPayable:""
      },
      disposeResTitle: "线下缴费",
      orderContentVisible: false,
      //支付方式
      selectPaymentList: {
        0: "现金支付",

        1: "银行转账",

        2: "支付宝支付",

        3: "微信转账",
      },
    };
  },
  components: {
    CSDialog,
  },
  methods: {
    selectPayment(item) {
      this.editRecharge.target = +item;
    },
    //确认缴费按钮
    reopenWorkOrder() {
      if(this.editRecharge.photos.length == 0){
        this.$vc.toast("请上传付款凭证");
        return
      }
      this.$fly
        .post(pcOffLinePropUrl, {
          id: this.editRecharge.id, //账单id
          payChannel: this.editRecharge.target, //支付渠道 0现金 1银行 2 支付宝 3 微信
          payOrder: this.editRecharge.photos, //支付凭证
          payCommit: this.editRecharge.textarea, //备注
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.$vc.toast("成功缴费");
          this.$vc.emit(this.$route.path,"successOff",{})
          this.orderContentVisible = false;
        });
    },
    //删除图片
    deleteFacePhoto(index) {
      this.editRecharge.photos.splice(index, 1);
    },
    //触发上传图片
    emitChoosePhoto() {
      $("#uploadOwnerPhoto").trigger("click");
    },
    //上传图片
    showImage(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2M!");
          return false;
        }
        var reader = new FileReader(); //新建FileReader对象
        reader.readAsDataURL(file); //读取为base64
        reader.onloadend = async (e) => {
          let imgSrc = await this.uploadPhoto(reader.result).catch((err) => {
            this.$vc.toast("上传图片错误,请重新上传");
          });
          if (imgSrc && imgSrc != "") {
            this.editRecharge.photos.push(imgSrc);
          }
        };
        event.target.value = "";
      }
    },
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
          "uploadImg",
          "upload",
          {
            img: base64,
          },
          {
            headres: {
              "Content-Type": "application/json",
            },
          },
          (resStr, res) => {
            reslove(res.body.fileSaveName);
          },
          (errText, err) => {
            reject(errText);
          }
        );
      });
    },
  },
  mounted() {
    this.$vc.on(this.$route.path, "OfflinePaymentBill", "showOm", (item) => {
      this.editRecharge = {
        target: 0, //支付方式
        textarea: "", //备注
        photos: [], //上传图片数组
        id: "",
        actualPayable:""
      };
      this.editRecharge.actualPayable = item.actualPayable;
      this.editRecharge.id = item.id;
      this.orderContentVisible = true;
    });
  },
};
</script>

<style scoped>
.imgSele {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
.el-input {
  width: 460px;
  height: 180px;
  background-color: red;
  vertical-align: top;
  background: #f0f0f0;
  border-radius: 5px;
  padding: 10px;
  font-size: 24px;
  font-weight: normal;
  resize: none;
}
.content-checkbox {
  display: inline-block;
  margin: 0 20px 14px 0;
}
.paymentMethod_cs {
  display: inline-block;
}
.commodit {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
</style>